body {
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.root {
    position: relative;
    min-height: 95vh;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
    color: black;
}

/* App drawer */
.MuiPaper-elevation16 {
    background-color: #ffffff !important;
}

.sr-only {
    position: absolute;
    left: -9999px;
}

@media (max-width: 600px) {
    .root {
        padding: 0 5%;
    }
}